import React from 'react';
import { SectionHeader } from './Section';
import { Grid, Button, Container, Typography } from '@material-ui/core';

const Description = (props) => {
    return (
        <Container>
            <SectionHeader>
                {props.header}
            </SectionHeader>

            <Grid container direction="column" justify={props.direction || 'left'} spacing="3">
                <Grid item style={{maxWidth: '30rem'}}>
                    <Typography variant="h5">
                        {props.content}
                    </Typography>
                </Grid>
                
                {props.withButton && <Grid item>
                    <Button variant="outlined" color="secondary" size="small">
                        {props.button}
                    </Button>
                </Grid>}
            </Grid>
        </Container>
    );
};

const BlogDesc = (props) => (
    <Description 
        header="How's it going"
        content="Nowadays it is hard to keep up with all the trends. That's why, often while researching something interesting and new, I like to jote my thoughts on my blog. Be welcomed to visit!"
        withButton
        button="Visit blog"
    />
);

const ProjectsDesc = (props) => (
    <Description
        header="Personal projects"
        content="Most of my projects are related to Game and Web Development areas. I created various tools and webpages."
        withButton
        button="Visit my GitHub profile"
    />
);

const ExperienceDesc = (props) => (
    <Description
        header="Experience"
        content="I have worked in various areas of Software Development using variety of technologies."
    />
);

export { BlogDesc, ProjectsDesc, ExperienceDesc }