import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    unit: {
        width: '1rem', 
        height: '1rem',
        backgroundColor: theme.palette.secondary.main
    }
}));

const ContributionsGraph = (props) => {
    const classes = useStyles();

    return (   
        <Grid container spacing={1} style={{width: '10rem', ...props.style}}>
            {
                Array(31).fill(1).map((x, index) => {
                    return (
                        <Grid item>
                            <div style={{opacity: Math.random() * 0.3}} className={classes.unit}/>
                        </Grid>
                    )
                })
            }
        </Grid>
    );
}

export { ContributionsGraph };