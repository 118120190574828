import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    header: {
        fontWeight: 'bold',
        fontSize: 50,
        marginBottom: '4rem',
    },
    blackHeader: {
        backgroundColor: 'black',
        color: 'white',
        padding: '30px',
        fontWeight: 'bold',
        fontSize: 50,
        display: 'inline-block'
    },
    whiteHeader: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        padding: '30px',
        fontWeight: 'bold',
        fontSize: 50,
        display: 'inline-block'
    },
    section: {
        marginTop: '15%',
        color: 'white'
    },
    bar: {
        marginTop: '1rem', 
        width: '50px', 
        height: '10px', 
        backgroundColor: theme.palette.secondary.main
    }
}));

const SectionHeader = (props) => {
    const classes = useStyles();

    return (
        <div style={props.style} className={classes.header}>
            {props.children}
            <div className={classes.bar}></div>
        </div>
    );
};

const SectionDarkHeader = (props) => {
    const classes = useStyles();
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    return (
        <div style={props.style} className={prefersDarkMode ? classes.whiteHeader : classes.blackHeader}>
            {props.children}
        </div>
    );
};

const Section = (props) => {
    const classes = useStyles();

    return (
        <div style={props.style} className={classes.section}>
            {props.children}
        </div>
    );
};

export { SectionHeader, SectionDarkHeader, Section }