import React from 'react';
import Particles from 'react-particles-js';

const Background = (props) => {
    return (
        <div style={{
            background: 'transparent',
            height: '600px',
            position: 'relative',
            display: 'block',
            ...props.style
        }}
        >
            {props.children}
            <Particles
                height="600px"
                width="300%"
                    params={{
                        "particles": {
                            "number": {
                                "value": 50
                            },
                            "size": {
                                "value": 3
                            },
                            "color": {
                                value: "#00a8cc"
                            },
                            "line_linked": {
                                "color": "#0c7b93"
                            }
                        },
                        "interactivity": {
                            "events": {
                                "onhover": {
                                    "enable": false,
                                    "mode": "repulse"
                                }
                            }
                        }
                    }}
            />
        </div>
    );
}

export { Background };