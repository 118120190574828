import React from 'react';
import { SectionDarkHeader } from './Section';

const Landing = (props) => {
    return (
        <div style={{display: 'flex', textAlign: 'left'}}>
            <div style={{display: 'block', textAlign: 'right'}}>
                <SectionDarkHeader>
                    Marek Iwaniuk
                </SectionDarkHeader>
                <div/>
                <SectionDarkHeader style={{
                    fontSize: 30,
                    padding: '20px'
                }}>
                    Taking care of <span style={{color: 'rgba(195,61,34,1)', fontStyle: 'italic'}}>#software</span>
                </SectionDarkHeader> 
            </div>
        </div>
    );
};

export { Landing };