import React, { useState, useEffect } from 'react';
import { Card, CardActions, CardContent, Typography, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    stats: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '13px',
        marginLeft: '0.5rem',
        alignItems: 'center'
    },
    language: {
        width: '10px',
        height: '10px',
        backgroundColor: 'orange',
        borderRadius: '100%',
        marginRight: '0.25rem'
    },
    card: {
        backgroundColor: theme.palette.primary.main
    }
}));

const Project = (props) => {
    const classes = useStyles();

    return (
        <Card variant="outlined" style={{width: '280px', minWidth: '280px'}} className={classes.card}>
            <CardContent>
                <span style={{display: 'flex', alignItems: 'center'}}>
                    <svg class="octicon octicon-repo" viewBox="0 0 12 16" version="1.1" width="12" height="16" aria-hidden="true" style={{marginRight: '0.5rem'}}>
                        <path style={{fill: 'white'}} fill-rule="evenodd" d="M4 9H3V8h1v1zm0-3H3v1h1V6zm0-2H3v1h1V4zm0-2H3v1h1V2zm8-1v12c0 .55-.45 1-1 1H6v2l-1.5-1.5L3 16v-2H1c-.55 0-1-.45-1-1V1c0-.55.45-1 1-1h10c.55 0 1 .45 1 1zm-1 10H1v2h2v-1h3v1h5v-2zm0-10H2v9h9V1z"></path>
                    </svg>
                    <Typography variant="h5">
                        {props.title}
                    </Typography>
                </span>

                <Typography variant="h7" color="textSecondary">
                    {props.text}
                </Typography>
                
            </CardContent>
            <CardActions>
                <span className={classes.stats}>
                    <div className={classes.language} style={{backgroundColor: props.languageColor}}></div>
                    <p>{props.primaryLanguage}</p>
                </span>

                <Button style={{marginLeft: 'auto'}} color="secondary" size="small">See code</Button>
            </CardActions>
        </Card>
    );
};

const Projects = (props) => {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        setProjects([
            {
                title: "kqtus/convapi",
                text: "A set of parsers for virtual asset formats for old Grand Theft Auto games.",
                primaryLanguage: 'C++',
                languageColor: 'DodgerBlue'
            },
            {
                title: "kqtus/fronted",
                text: "Online editor of html files.",
                primaryLanguage: 'JavaScript',
                languageColor: 'orange'
            },
            {
                title: "kqtus/croaker-edu",
                text: "Immitation of Twitter using Blazor.",
                primaryLanguage: "C#",
                languageColor: 'SeaGreen'
            }
        ]);
    });

    return (
        <Grid container item direction="row" spacing={1} xs style={{maxWidth: '50rem'}}>
            {
            projects.map(proj => 
                <Grid item>
                    <Project {...proj}/>
                </Grid>
            )
            }
        </Grid> 
    );
}

export { Projects }