import React from 'react';
import { makeStyles } from '@material-ui/core';

const withStyles = makeStyles(theme => ({
    blue: {
        background: 'radial-gradient(farthest-corner at 4rem 4rem, rgba(20,40,80,1) 0%, rgba(2,12,33,1) 100%)',
        boxShadow: '100px 63px 202px -44px rgba(0,0,0,0.66)'
    },
    orange: {
        background: 'radial-gradient(circle, rgba(195,61,34,1) 0%, rgba(130,57,8,1) 100%)',
        boxShadow: '0px 0px 85px -3px rgba(255,176,140,0.28)'
    },
    red: {
        background: 'radial-gradient(farthest-corner at 1rem 1rem, rgba(118,134,255,1) 0%, rgba(15,62,77,1) 100%)',
        boxShadow: '-23px -7px 145px 0px rgba(0,0,0,0.75)'
    },
}));



const Planet = (props) => {
    return (
        <div style={{width: `${props.diam}rem`, height: `${props.diam}rem`, borderRadius: '100%'}} {...props}/>
    );
};

const BluePlanet = (props) => {
    const classes = withStyles();

    return (
        <Planet {...props} className={classes.blue}/>
    );
};

const OrangePlanet = (props) => {
    const classes = withStyles();

    return (
        <Planet {...props} className={classes.orange}/>
    );
};

const RedPlanet = (props) => {
    const classes = withStyles();

    return (
        <Planet {...props} className={classes.red}/>
    );
};

export { BluePlanet, OrangePlanet, RedPlanet };