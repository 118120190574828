import React from 'react';
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Landing } from '../components/Landing';
import { BlogDesc, ProjectsDesc, ExperienceDesc } from '../components/Descriptions';
import { Projects } from '../components/Projects';
import { Section } from '../components/Section';
import { Background } from '../components/Background';
import { BluePlanet, OrangePlanet, RedPlanet } from '../components/Planet';
import { ContributionsGraph } from '../components/ContributionsGraph';

const theme = createMuiTheme({
  palette: {
    primary: {
        light: '#27496d',
        main: '#142850',
        dark: '#020c21'
    },
    secondary: {
        main: '#00a8cc',
        dark: '#0c7b93'
    },
    type: 'dark'
  },
});


export default class Index extends React.Component {
    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <Parallax pages={3} horizontal>
                    {/*<Navbar />*/}
                    <ParallaxLayer offset={0.0} factor={1} speed={0.0}>
                        <div style={{width: '300%', height: '100%', background: 'linear-gradient(135deg, rgba(20,40,80,1) 0%, rgba(2,12,33,1) 100%)'}}/>
                    </ParallaxLayer>
                    <ParallaxLayer offset={0.1} speed={0.2}>
                        <Background />
                    </ParallaxLayer>
                    <ParallaxLayer offset={0.15} speed={0.5}>
                        <div style={{marginTop: '9%'}}>
                            <OrangePlanet diam={25} />
                        </div>
                    </ParallaxLayer>
                    
                    <ParallaxLayer offset={0.2} speed={1.2}>
                        <Section>
                            <Landing/>   
                        </Section>
                    </ParallaxLayer>
                
                    <ParallaxLayer offset={0.4} speed={0.5}>
                        <Section>
                            <BlogDesc/>  
                        </Section>
                    </ParallaxLayer>
                    
                    <ParallaxLayer offset={1.05} speed={-0.05}>
                        <Section>
                            <ProjectsDesc/>
                        </Section>  
                    </ParallaxLayer>  

                    <ParallaxLayer offset={0.9} speed={0.7}>
                        <div style={{marginTop: '25%'}}>
                            <BluePlanet diam={80} />
                        </div>
                    </ParallaxLayer>

                    <ParallaxLayer offset={1.48} speed={0.2}>
                        <Section>
                            <ContributionsGraph style={{marginTop: '-1rem'}}/>
                        </Section>
                    </ParallaxLayer>
                    <ParallaxLayer offset={2.05} speed={-0.05}>
                        <div style={{marginTop: '40%'}}>
                            <RedPlanet diam={5} />
                        </div>
                    </ParallaxLayer>
                    <ParallaxLayer offset={2.1} speed={-0.1}>
                        <div style={{marginTop: '35%'}}>
                            <RedPlanet diam={20} />
                        </div>
                    </ParallaxLayer>
                    <ParallaxLayer offset={2.3} speed={-0.2}>
                        <div style={{marginTop: '40%'}}>
                            <RedPlanet diam={10} />
                        </div>
                    </ParallaxLayer>
                    <ParallaxLayer offset={1.5} speed={0.1}>
                        <Section>
                            <Projects/>
                        </Section>
                    </ParallaxLayer>  
                    <ParallaxLayer offset={2.05} speed={-0.05}>
                        <Section>
                            <ExperienceDesc/>
                            {/* TODO: Add those
                            Languages: C#, C++, Java, JavaScript, Scala, Python, CSS, HTML
                            Frameworks & libraries: .NET, .NET Core, Qt, React, jQuery, Node.js, EF, 
                            Tools: Docker, Firebase, Postman, SQL Server, Azure
                            Use planets as some backgrounds. 
                            */}
                        </Section>  
                    </ParallaxLayer> 
                </Parallax>
            </MuiThemeProvider>
        )  
    }
}